* {
  margin: 0;
  padding: 0;
}
#root{
   --toastify-color-success: #388e3c;
}
input:invalid {
  color: red;
}
.data-table-extensions > .data-table-extensions-filter > .icon {
  position: relative;
}
.paddingTop3P {
  padding-top: 1%;
}
.paddingRight3P {
  padding-right: 3%;
  width: fit-content;
}
.mui-error-color{
  color: #d32f2f !important;
}
.gm-style-iw button[title="Close"] {
  display: none !important;
}
