// Here you can add other styles
.dropzone-content {
  border: 1px groove rgba(0, 0, 0, 0.87);
  height: 100px;
  padding: 20px;
  margin: 0px auto;
  margin-bottom: 10px;
}

.rcw-launcher {
  margin-bottom: 50px;
}

.overlay-existing {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100px;
  opacity: 0;
  transition: 0.1s ease;
  background-color: blue;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100px;
  opacity: 0;
  transition: 0.1s ease;
  background-color: red;
}

.file-item {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.file-item:hover .overlay {
  opacity: 8;
}

.file-item:hover .overlay-existing {
  opacity: 8;
}

.icon {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 40;
  background: none;
  border: 0;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // -ms-transform: translate(-50%, -50%);
  // text-align: center;
}

.delete-icon {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: 0;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // -ms-transform: translate(-50%, -50%);
  // text-align: center;
}

.fa-user:hover {
  color: #eee;
}

.buttonCustom {
  background-color: red;
  color: white;
}

//******** FORMS ********//

.formGroup {
  &__label {
    font-weight: 500;
    font-size: 0.85rem;
  }
}

.preview-hr {
  border-top: 1px solid #dbdfea;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  cursor: default;
  background-color: rgb(241, 240, 229);
  z-index: 30 !important;
  width: 100%;

  li {
    list-style-type: none;
    :hover {
      background-color: palegoldenrod;
    }
  }
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 100%;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.css-fvipm8 {
  width: 100%;
}

html:not([dir="rtl"]) .sidebar {
  margin-left: 0px !important;
  // overflow-y: scroll;
}

@media (max-width: 992px) {
  .sidebar {
    position: relative !important;
  }
  .sidebar-fixed .sidebar {
    position: relative !important;
  }
}
